import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import { HttpClient,HttpResponse, HttpRequest, HttpEventType }    from '@angular/common/http';

import { AppConfig } from '../app.config'
import { Observable, Subject,  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DkwhService {


  token: string;

  constructor(private http:HttpClient, private appConfig:AppConfig) { 
    
  }

  getInstagramMedia(): Observable<any>{
    return this.http
    .get(this.appConfig.baseUrl+"instamedia");
  }

  sendContactMessage(email,name,message): Observable<any>{
    return this.http
    .post(this.appConfig.baseUrl+"setContactForm",{email:email,name:name,message:message});
  }

  subscribeNewsletter(email): Observable<any>{
    return this.http
    .post(this.appConfig.baseUrl+"subscribe",{email:email});
  }

  getPodcastItems(offset,limit): Observable<any>{
    return this.http
    .get(this.appConfig.baseUrl+`podcast/episodes/dkwh?skip=${offset}&limit=${limit}`).pipe(map((res:any) => res.data));
  }

  getEpisode(id): Observable<any>{
    return this.http
    .get(this.appConfig.baseUrl+`podcast/episode/${id}`).pipe(map((res:any) => res.data));
  }

  getSlides(): Observable<any>{
    return this.http
    .get(`${this.appConfig.baseUrl}ressource/getslides`);
  }

  

}
