import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { StreamState } from '../interfaces/stream-state';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  private stop$ = new Subject();
  private audioObj = new Audio();
  audioEvents = [
    "ended",
    "error",
    "play",
    "playing",
    "pause",
    "timeupdate",
    "canplay",
    "loadedmetadata",
    "loadstart"
  ];

  private state: StreamState = {
    playing: false,
    readableCurrentTime: "",
    readableDuration: "",
    duration: undefined,
    currentTime: undefined,
    canplay: false,
    error: false,
    stopped: false,
    pause: false
  }

  private stateChange: BehaviorSubject<StreamState> = new BehaviorSubject(this.state);
  private stopped: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  private updateStateEvents(event: Event): void{
    switch(event.type){
      case "canplay":
        this.state.duration = this.audioObj.duration;
        this.state.readableDuration = this.formatTime(this.state.duration);
        this.state.canplay = true;
        break;
      case "playing":
        this.state.playing = true;
        this.state.pause = false;
        break;
      case "pause":
        this.state.playing = false;
        this.state.pause = true;
        break;
      case "timeupdate":
        this.state.currentTime = this.audioObj.currentTime;
        this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
        break;
      case "error":
        this.resetState();
        this.state.error = true;
        break;
    }

    this.stateChange.next(this.state);
  }

  private resetState() {
    this.state = {
      playing: false,
      readableCurrentTime: "",
      readableDuration: "",
      duration: undefined,
      currentTime: undefined,
      canplay: false,
      error: false,
      stopped: true,
      pause: false
    }
    this.state.stopped = false;
  }

  getState(): Observable<StreamState>{
    return this.stateChange.asObservable();
  }

  stoppChanged(): Observable<boolean>{
    return this.stopped.asObservable();
  }

  private streamObservable(url){
    return new Observable(observer => {
      if(this.state.pause === false){
        this.audioObj.src = url;
        this.audioObj.load();
      }
      this.audioObj.play();

      const handler = (event: Event) => {
        this.updateStateEvents(event);
        observer.next(event);
      }

      this.addEvents(this.audioObj, this. audioEvents, handler);

      return () => {
        //Stop Playing
        debugger;
        this.audioObj.pause();
        this.audioObj.currentTime = 0;

        this.removeEvents(this.audioObj, this.audioEvents, handler);

        this.resetState();
      }

    });
  }

  private addEvents(obj, events, handler){
    events.forEach(event => {
      obj.addEventListener(event,handler);
    });
  }

  private removeEvents(obj, events, handler){
    events.forEach(event => {
      obj.removeEventListener(event,handler);
    });
  }

  public playStream(url){
    return this.streamObservable(url).pipe(takeUntil(this.stop$))
  }

  public play() {
    this.audioObj.play();
  }

  public pause() {
    this.audioObj.pause();
  }

  public stop() {
    this.stop$.next();
    this.stopped.next(true);
  }

  public seekTo(seconds) {
    this.audioObj.currentTime = seconds;
  }

  public addSeconds(seconds){
    let nextTime = this.audioObj.currentTime + seconds;
    this.audioObj.currentTime = Math.min(this.audioObj.duration, nextTime);
  }

  public popSeconds(seconds){
    let nextTime = this.audioObj.currentTime - seconds;
    this.audioObj.currentTime = Math.max(0, nextTime);
  }

  formatTime(time: number, format: string = "HH:mm:ss"){
    const momentTime = time * 1000;
    return moment.utc(momentTime).format(format);
  }


}
